<template>
  <section>
    <div class="container py-15 lg:py-25.5 overflow-hidden">
      <v-content
          data-aos="fade-right"
          data-aos-once="true"
          class="text-2xl leading-3xl lg:text-3xl lg:leading-44px"
          default_value="Danh mục hỗ trợ"
          option_key="faqPage.titleSupport"
      ></v-content>
      <div class="mt-10 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-5">
        <wrapper-content
            v-if="data && data.length > 0"
            v-for="(item, index) in data"
            :loading="loading"
            :key="item?.id"
            :backgroundImage="listImage[index]?.image"
            :title="item?.title"
            :icon="item?.icon"
            :intro="item?.intro"
            :url="item?.url"
            :date_created="item?.date_created"
        />
      </div>
      <v-content
          data-aos="fade-up"
          data-aos-once="true"
          class="text-black font-[550] leading-10 text-2xl xl:text-30px mt-15 xl:mt-20 mb-10 xl:mb-11 text-center"
          default_value="Trợ giúp từ cộng đồng"
          option_key="faqPage.titleSupportBottom"
      ></v-content>
      <div
          data-aos="zoom-out"
          data-aos-once="true"
          class="flex flex-col md:flex-row items-center justify-center gap-10 md:gap-25"
      >
        <a :href="links_global?.facebook"
           target="_blank"
           @click="useEventTracking(SOCIAL_EVENT_ID, SOCIAL_FACEBOOK_KEY)"
           class="flex items-center gap-2 group">
          <p
              class="flex-none rounded-full w-10 h-10 bg-primary group-hover:bg-accent-01 duration-200 flex flex-col items-center justify-center"
          >
            <span class="i-custom-facebook-faqs w-6 h-6 text-white"></span>
          </p>
          <p class="text-xs xl:text-sm font-semibold">
            <span class="uppercase text-black/50">{{ $t('SUPPORT_THROUGH') }}</span> <br/>
            <span class="xl:text-lg text-black font-semibold">Facebook</span>
          </p>
        </a>
        <a :href="links_global?.messenger"
           target="_blank"
           @click="useEventTracking(SOCIAL_EVENT_ID, SOCIAL_MESSENGER_KEY)"
           class="flex items-center gap-2 group">
          <p
              class="flex-none rounded-full w-10 h-10 bg-primary group-hover:bg-accent-01 duration-200 flex flex-col items-center justify-center"
          >
            <span class="i-mdi:facebook-messenger w-6 h-6 text-white"/>
          </p>
          <p class="text-xs xl:text-sm font-semibold">
            <span class="uppercase text-black/50">{{ $t('SUPPORT_THROUGH') }}</span> <br/>
            <span class="xl:text-lg text-black font-semibold">Messenger</span>
          </p>
        </a>
        <a :href="links_global?.zalo"
           target="_blank"
           @click="useEventTracking(SOCIAL_EVENT_ID, SOCIAL_ZALO_KEY)"
           class="flex items-center gap-2 group">
          <p
              class="flex-none rounded-full w-10 h-10 bg-primary group-hover:bg-accent-01 duration-200 flex flex-col items-center justify-center"
          >
            <IconsZaloFaqs class="w-8 h-8 text-white"></IconsZaloFaqs>
          </p>
          <p class="text-xs xl:text-sm font-semibold">
            <span class="uppercase text-black/50">{{ $t('SUPPORT_THROUGH') }}</span> <br/>
            <span class="xl:text-lg text-black font-semibold">Zalo OA</span>
          </p>
        </a>
      </div>
    </div>
  </section>
</template>
<script lang="ts" setup>
import WrapperContent from './__WrapperContent.vue'
import {
  SOCIAL_EVENT_ID,
  SOCIAL_FACEBOOK_KEY,
  SOCIAL_MESSENGER_KEY,
  SOCIAL_ZALO_KEY,
  useEventTracking
} from "../../../composables/ackee/event";

const links_global = inject('globalSettings')?.links[0]

defineProps({
  data: {
    type: Array
  },
  loading: {
    type: Boolean
  }
})
const listImage = [
  {
    image: '/images/faq/bg-wrapper-faq-1.png'
  },
  {
    image: '/images/faq/bg-wrapper-faq-2.png'
  },
  {
    image: '/images/faq/bg-wrapper-faq-3.png'
  },
  {
    image: '/images/faq/bg-wrapper-faq-4.png'
  },
  {
    image: '/images/faq/bg-wrapper-faq-5.png'
  },
  {
    image: '/images/faq/bg-wrapper-faq-1.png'
  }
]
</script>
