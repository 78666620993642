<template>
  <div class="relative h-50 lg:h-81 aspect-3/2 md:aspect-16/5 lg:aspect-16/9 w-full">
    <div class="absolute inset-0 z-1 bg-wrapper overflow-hidden">
      <div class="relative w-full h-full">
        <div class="absolute -bottom-10 right-50 h-full">
          <img
              src="/images/about/pattern_Z.svg"
              class="object-cover w-full h-full transform scale-150"
              alt="Banner Pattern"
          />
        </div>
      </div>
    </div>
    <div class="relative z-10 container h-full flex justify-start">
      <v-content
          class="text-3xl lg:text-56px font-[550] text-white leading-10 lg:leading-17 mt-auto pb-10 xl:pb-22 w-full"
          data-aos="fade-right"
          data-aos-once="true"
          default_value=" Có thắc mắc ? Tìm giải pháp ở đây!"
          option_key="faqPage.contentBannerHeader"
      ></v-content>
    </div>
  </div>
</template>
<script setup lang="ts"></script>
<style scoped>
.bg-wrapper {
  background: radial-gradient(247.42% 100.06% at 32.37% 100%, #ffa17d 20.03%, #ff8050 42.75%, #ed5a22 100%);
}
</style>
