<template>
  <div class="relative aspect-480/134">
    <div class="absolute inset-0 z-1 bg-wrapper overflow-hidden">
      <div class="relative w-full h-full">
        <div class="absolute -top-20 -left-30 h-full hidden lg:block">
          <img src="/images/circle/hinh2.svg" class="object-cover w-full h-full" alt="Banner Pattern"/>
        </div>
        <div class="hidden md:block absolute top-30 -right-100 h-full">
          <img src="/images/circle/hinh10.svg" class="object-cover w-full h-full" alt="Banner Pattern"/>
        </div>
      </div>
    </div>
    <div
        class="relative container z-10 pt-10 pb-50 lg:pt-30 lg:pb-40 xl:pb-50 xl:pt-41 text-white text-2xl leading-3xl lg:text-3xl lg:leading-44px overflow-hidden"
    >
      <v-text tag="h2" class="w-full lg:w-125 font-[550]" option_key="faqPage.titleBannerBottom"></v-text>
      <div class="flex flex-col md:flex-row items-center gap-4 lg:gap-2.5 uppercase mt-14">
        <a
            data-aos="fade-right"
            data-aos-once="true"
            target="_blank"
            :href="`tel:${links_global?.hotline}`"
            class="btn-effect flex items-center justify-center gap-1.5 bg-white text-accent-01 w-54 py-3 rounded-3xl btn-effect-light"
        >
          <span class="i-ic:baseline-local-phone text-2xl"></span>
          <span class="text-sm font-semibold"> {{ $t('CALL') }} hotline</span>
        </a>
        <a
            data-aos="fade-left"
            data-aos-once="true"
            :href="links_global?.messenger"
            target="_blank"
            class="btn-effect flex items-center justify-center gap-1.5 bg-white text-accent-01 w-54 py-3 rounded-3xl btn-effect-light"
        >
          <span class="i-mdi:facebook-messenger text-2xl"></span>
          <span class="text-sm font-semibold"> live chat</span>
        </a>
      </div>

      <div
          data-aos="fade-down-left"
          data-aos-delay="300"
          data-aos-once="true"
          class="w-50 lg:w-90 aspect-94/71 absolute bottom-5 md:bottom-50 right-[20%] sm:right-[38%] md:right-30 transform -translate-x-1/2 z-1"
      >
        <img src="/images/brand-iz4.png" alt="" class="w-full h-full object-cover animationSway"/>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
const links_global = inject('globalSettings')?.links[0]
</script>
<style scoped>
.bg-wrapper {
  background: radial-gradient(162.55% 98.99% at 73.6% 64.69%, #ffa17d 5.03%, #ff8050 42.75%, #ed5a22 100%);
}
</style>
