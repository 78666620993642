<template>
  <banner-header/>
  <section-support-directory :data="dataRender?.model"/>
  <banner-bottom/>
</template>
<script lang="ts" setup>
import BannerHeader from './components/BannerHeader.vue'
import SectionSupportDirectory from './components/SectionSupportDirectory.vue'
import BannerBottom from './components/BannerBottom.vue'
import {useFaq} from '../../composables/faq/index'
import {useGetLangCurrent} from '../../stores/useLanguageMenu'

const {getFaqIndex} = useFaq()
// const { data: dataRender } = await getFaqIndex(useRoute().params.langCode)
const dataRender = await getFaqIndex(useRoute().params.langCode)
const lang = useRoute().params.lang

useDirectusCollectionSeo('faq_index', {}, lang)
const store = useGetLangCurrent()
onMounted(() => {
  store.lang_switch = dataRender.lang_switch
})
</script>
